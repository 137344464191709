import React from 'react';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `contactpage`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const MAINCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__maincontainer`;
const SUBHEADER_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__subheader`;
const TEXT_CLASSNAME: string = `${MAINCONTAINER_CLASSNAME}__text`;

const ContactPage = (): JSX.Element => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={HEADER_CLASSNAME}>{site.contactPage.mainheader}</div>
      <div className={MAINCONTAINER_CLASSNAME}>
        <div className={SUBHEADER_CLASSNAME}>{site.contactPage.phonenumberHeader}</div>
        <div className={TEXT_CLASSNAME}>{site.general.phonenumber}</div>
        <div className={SUBHEADER_CLASSNAME}>{site.contactPage.emailHeader}</div>
        <div className={TEXT_CLASSNAME}>{site.general.email}</div>
      </div>
    </div>
  );
}

export default ContactPage